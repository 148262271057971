'use client';
import { useState } from 'react';
import { useSession } from 'next-auth/react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Check, CircleDashed, RefreshCcwDot, X } from 'lucide-react';
import { Pencil } from 'lucide-react';

const EditWorkspaceForm = ({ workspace, onWorkspaceUpdated }) => {
  const { data: session } = useSession();
  const [workspaceId, setWorkspaceId] = useState(workspace.id);
  const [title, setTitle] = useState(workspace.title);
  const [workspaceUrlSlug, setWorkspaceUrlSlug] = useState(
    workspace.workspaceUrlSlug,
  );
  const [description, setDescription] = useState(workspace.description);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    const formattedSlug = newTitle
      .toLowerCase()
      .trim()
      .replace(/[^a-zA-Z0-9]+/g, '-');
    setWorkspaceUrlSlug(formattedSlug);
  };

  const handleSaveWorkspace = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const ownerId = session?.user?.id;

    try {
      const response = await axios.put(
        `/api/edit-a-workspace/${workspace.id}`,
        {
          title,
          workspaceUrlSlug,
          description,
        },
      );

      if (response.status === 200) {
        onWorkspaceUpdated(response.data);
        toast.success(
          <div className='text-base font-medium text-muted-foreground'>
            Your Workspace &quot;
            <span className='text-base font-bold text-rose-500'>
              {workspace?.title}
            </span>
            &quot; is updated successfully!
          </div>,
          {
            position: 'bottom-right',
          },
        );

        setOpenDialog(false);
      } else {
        throw new Error('Failed to update workspace');
      }
    } catch (error) {
      toast.error('Error updating workspace');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        variant='outline'
        onClick={(e) => {
          e.stopPropagation(); // Prevent card click event
          setOpenDialog(true);
        }}
        className='text-slate-400 bg-emerald-100 hover:bg-emerald-200 dark:bg-background'>
        <Pencil className='size-5 text-teal-500 hover:text-teal-700' />
      </Button>

      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogContent
          onClick={(e) => e.stopPropagation()}
          className='sm:max-w-[425px]'>
          <DialogHeader>
            <DialogTitle className='font-bold text-teal-600 dark:text-muted-foreground'>
              Edit Workspace!
            </DialogTitle>
          </DialogHeader>
          <form
            onSubmit={handleSaveWorkspace}
            onClick={(e) => e.stopPropagation()}>
            <div className='grid gap-4 py-4'>
              <div className='grid grid-cols-4 items-center gap-4'>
                <Label
                  htmlFor='title'
                  className='text-sm font-semibold text-muted-foreground'>
                  Title
                </Label>
                <Input
                  type='text'
                  id='title'
                  value={title}
                  onChange={handleTitleChange}
                  className='col-span-3 text-sm font-semibold text-muted-foreground'
                  required
                />
              </div>
              <div className='grid grid-cols-4 items-center gap-4'>
                <Label
                  htmlFor='workspaceUrlSlug'
                  className='text-sm font-semibold text-muted-foreground'>
                  URL Slug
                </Label>
                <Input
                  type='text'
                  id='workspaceUrlSlug'
                  value={workspaceUrlSlug}
                  readOnly
                  className='col-span-3 text-sm font-semibold text-muted-foreground'
                />
              </div>
              <div className='grid grid-cols-4 items-center gap-4'>
                <Label
                  htmlFor='description'
                  className='text-sm font-semibold text-muted-foreground'>
                  Description
                </Label>
                <Textarea
                  id='description'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className='col-span-3 text-sm font-semibold text-muted-foreground'
                />
              </div>
            </div>
            <DialogFooter>
              <Button
                variant='outline'
                type='submit'
                disabled={isLoading}
                className='text-sm font-bold text-teal-600 hover:text-emerald-600 bg-rose-50 hover:bg-rose-100 dark:bg-background'>
                {isLoading ? (
                  <CircleDashed className='animate-spin size-5 text-rose-600 mr-3' />
                ) : (
                  <RefreshCcwDot className='size-5 text-teal-600 mr-2' />
                )}
                {isLoading ? 'Updating Workspace...' : 'Update Workspace'}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditWorkspaceForm;
