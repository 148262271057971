// app/workspaces/page.jsx
'use client';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';
import useSWR from 'swr';
import usePageProtection from '@/components/usePageProtection';

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import Image from 'next/image';
import { Button } from '@/components/ui/button';
import CreateWorkspaceForm from '@/components/workspace/CreateWorkspaceForm';
import EditWorkspaceForm from '@/components/workspace/EditWorkspaceForm';
import DeleteWorkspace from '@/components/workspace/DeleteWorkspace';
import UserLeftNavigationMenu from '@/components/navigation/UserLeftNavMenu';
import { ChevronsRightLeft } from 'lucide-react';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';

const fetcher = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Failed to fetch workspaces list');
  }
  return response.json();
};

const WorkspacesListPage = ({ params }) => {
  const { data: session, status } = useSession();
  const router = useRouter();
  const [workspaceId, setWorkspaceId] = useState(null);
  const [workspaces, setWorkspaces] = useState([]);
  const [isOpen, setIsOpen] = useState(false); // For mobile navigation

  const handleCardClick = (workspaceUrlSlug) => {
    router.push(`/workspaces/${workspaceUrlSlug}`);
  };

  const toggleSheet = () => setIsOpen(!isOpen);
  const closeSheet = () => setIsOpen(false);

  // Fetching All Workspaces for the current User or Owner
  const ownerId = session?.user?.id;
  const { data: workspaceData, error: workspaceDataError } = useSWR(
    () => (ownerId ? `/api/get-all-workspaces/?ownerId=${ownerId}` : null),
    fetcher,
  );
  useEffect(() => {
    if (workspaceData) {
      setWorkspaces(workspaceData);
      setWorkspaceId(workspaceData.id);
    }
    if (workspaceDataError) {
      console.log('Error fetching workspaces:', workspaceDataError.message);
    }
  }, [workspaceData, workspaceDataError]);

  // Use the following hook where route should be protected
  const { shouldHideUI } = usePageProtection();

  if (shouldHideUI) {
    return null; // Or render a specific component instead of null
  }

  const handleWorkspaceDeleted = (workspaceId) => {
    setWorkspaces(workspaces.filter((w) => w.id !== workspaceId));
  };

  return (
    <div className="pt-2 pb-4 w-full sm:px-2 md:px-4 lg:px-6 mx-auto">
      {/* Workspaces Left side bar for mobile screens */}
      <div className="w-full lg:hidden flex justify-end px-1">
        <Sheet open={isOpen} onOpenChange={toggleSheet}>
          <SheetTrigger asChild className="my-2 mt-16 md:mt-1">
            <Button
              variant="outline"
              className="bg-slate-50 dark:bg-background w-full"
            >
              <ChevronsRightLeft className="size-6 font-bold text-teal-600" />
            </Button>
          </SheetTrigger>
          <SheetContent
            side="left"
            className="w-68 mt-4 pt-12 pb-4 bg-slate-50 dark:bg-background rounded-lg min-h-fit"
          >
            <SheetHeader className="sr-only">
              <SheetTitle>User Profile Mobile Menu </SheetTitle>
              <SheetDescription>User Profile Mobile Menu.</SheetDescription>
            </SheetHeader>
            <UserLeftNavigationMenu closeSheet={closeSheet} />
          </SheetContent>
        </Sheet>
      </div>

      <div className="flex items-start justify-center pb-4 w-full gap-x-2">
        {/* Workspaces Left side bar for larger screens */}
        <div className="min-h-screen bg-slate-50 dark:bg-background rounded-sm hidden lg:block w-[270px]">
          <UserLeftNavigationMenu />
        </div>

        {/* Workspaces List Page */}
        <div className="min-h-screen bg-slate-50 dark:bg-background flex-grow flex flex-col items-center justify-start rounded-sm pt-2 py-4 px-6 w-full">
          {/* Workspaces Headers */}
          <div className="py-4 px-2 rounded w-full dark:border">
            <h1 className="pb-2 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold tracking-tight text-center text-muted-foreground">
              Hello{' '}
              <span className="text-teal-600">
                {session?.user?.fullName || 'CB User'}
              </span>{' '}
              !
            </h1>

            {workspaces.length ? (
              <h2 className="text-xl font-semibold tracking-tight first:mt-0 text-center text-muted-foreground">
                You have made{' '}
                <span className="text-teal-600 text-2xl font-bold">
                  {workspaces.length}
                </span>{' '}
                workspaces
              </h2>
            ) : (
              <h2 className="text-xl font-semibold tracking-tight first:mt-0 text-center text-muted-foreground">
                You don&apos;t have any workspace, please create one to get
                started.
              </h2>
            )}

            <hr className="mt-4 dark:hidden" />
          </div>

          {/* Workspaces Grid */}
          {workspaces.length ? (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 p-4 mt-2 rounded w-full dark:border">
              {workspaces.map((workspace) => (
                <Card
                  key={workspace.id}
                  onClick={() => handleCardClick(workspace.workspaceUrlSlug)}
                  className="cursor-pointer w-full"
                >
                  <CardHeader>
                    <CardTitle className="flex items-center gap-x-2 mb-2 border-b pb-2">
                      <>
                        {workspace?.workspaceLogo ? (
                          <Image
                            src={workspace.workspaceLogo}
                            alt={workspace.title}
                            height={30}
                            width={30}
                            unoptimized
                            className="rounded-full object-cover"
                          />
                        ) : (
                          <Image
                            src="/images/classified-billing-Logo.png"
                            alt="Static Logo"
                            height={30}
                            width={30}
                            unoptimized
                            className="rounded-full object-cover"
                          />
                        )}
                      </>
                      <span className="text-teal-600 text-base font-bold">
                        {workspace.title}
                      </span>
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="h-[130px]">
                    <p className="text-sm font-medium text-muted-foreground">
                      {workspace.description}
                    </p>
                  </CardContent>
                  <CardFooter className="flex flex-col justify-between h-[120px]">
                    <Button
                      variant="outline"
                      onClick={() =>
                        handleCardClick(workspace.workspaceUrlSlug)
                      }
                      className="text-sm font-bold text-teal-600 hover:text-emerald-600 w-full mb-4"
                    >
                      Start Working
                    </Button>
                    <div className="p-2 flex justify-between items-center w-full">
                      <EditWorkspaceForm
                        workspace={workspace}
                        onWorkspaceUpdated={(updatedWorkspace) => {
                          const updatedWorkspaces = workspaces.map((w) =>
                            w.id === updatedWorkspace.id ? updatedWorkspace : w,
                          );
                          setWorkspaces(updatedWorkspaces);
                        }}
                      />
                      <DeleteWorkspace
                        workspace={workspace}
                        workspaceId={workspace.id}
                        onWorkspaceDeleted={handleWorkspaceDeleted}
                      />
                    </div>
                  </CardFooter>
                </Card>
              ))}
            </div>
          ) : (
            <div className="w-full flex justify-center p-4 mt-4">
              <Button variant="primary" type="button" asChild size="lg">
                <CreateWorkspaceForm />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkspacesListPage;
