'use client';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { CircleDashed, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useRouter } from 'next/navigation';

const DeleteWorkspace = ({ workspace, workspaceId, onWorkspaceDeleted }) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async (e) => {
    e.stopPropagation();
    setIsDeleting(true);
    try {
      const response = await axios.delete(
        `/api/delete-a-workspace/${workspaceId}`,
      );

      if (response.status === 200) {
        toast.success(
          <div className='text-base font-medium text-muted-foreground'>
            Your Workspace &quot;
            <span className='text-base font-bold text-rose-500'>
              {workspace?.title}
            </span>
            &quot; is deleted successfully
          </div>,
          {
            position: 'bottom-right',
          },
        );
        onWorkspaceDeleted(workspaceId);
        router.refresh();
        // window.location.reload();
      } else {
        toast.error('Failed to delete workspace', {
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast.error('Error deleting workspace', { position: 'bottom-right' });
    } finally {
      setIsDeleting(false);
      setIsOpen(false);
    }
  };

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogTrigger asChild>
        <Button
          variant='outline'
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(true); // Open dialog manually
          }}
          className='text-slate-400 bg-red-100 hover:bg-red-200 dark:bg-background'>
          <Trash2 className='size-5 text-rose-600 hover:text-red-700' />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent onClick={(e) => e.stopPropagation()}>
        <AlertDialogHeader>
          <AlertDialogTitle className='text-xl font-bold text-rose-600'>
            Are you absolutely sure?
          </AlertDialogTitle>
          <AlertDialogDescription className='text-sm font-semibold text-muted-foreground'>
            This action cannot be undone. This will permanently delete the
            workspace &quot;
            <span className='text-sm font-bold text-red-400'>
              {workspace?.title || 'Workspace'}
            </span>
            &quot; and all its associated data.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            className='bg-emerald-50 hover:bg-emerald-200 dark:bg-background text-sm font-semibold text-emerald-500 hover:text-emerald-600'
            onClick={() => setIsOpen(false)}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={handleDelete}
            disabled={isDeleting}
            className='bg-red-50 hover:bg-red-100 dark:bg-background text-sm font-semibold text-red-400'>
            {isDeleting ? (
              <CircleDashed className='animate-spin size-6 text-red-600 mr-2' />
            ) : (
              <Trash2 className='text-red-400 font-bold size-4 mr-2' />
            )}
            {isDeleting ? 'Deleting Workspace.....' : 'Delete Workspace'}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteWorkspace;
