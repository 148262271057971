'use client';
import React, { useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from '@/components/ui/navigation-menu';
import {
  BookCheck,
  CreditCard,
  HelpCircle,
  Settings,
  SquareDashedKanban,
  SquareKanban,
  Tags,
  Ticket,
  UserRoundCog,
} from 'lucide-react';
import { SiBrandfolder } from 'react-icons/si';
import { GrBusinessService } from 'react-icons/gr';

const UserLeftNavigationMenu = ({ closeSheet }) => {
  const pathname = usePathname();
  const [activeItem, setActiveItem] = useState(pathname);

  useEffect(() => {
    setActiveItem(pathname);
  }, [pathname]);

  return (
    <div className="w-[270px] bg-slate-50 dark:bg-background rounded-sm pb-4 px-3 flex flex-col">
      {/* My Accounts Section */}
      <div className="w-[250px] py-4 px-4 rounded-md bg-neutral-500/10 hover:bg-neutral-500/20 dark:bg-background transition border-b dark:border">
        <span className="flex items-center gap-2">
          <UserRoundCog className="size-6 text-rose-500 font-bold dark:text-muted-foreground transition duration-200" />
          <h2 className="text-lg font-bold text-rose-500/85 dark:text-muted-foreground">
            My Account
          </h2>
        </span>
      </div>

      {/* Navigation Menu */}
      <NavigationMenu className="mt-1">
        <NavigationMenuList className="flex flex-col space-y-1">
          {/* Users Workspaces List */}
          <NavigationMenuItem className="ml-1 w-[250px] dark:border">
            <Link onClick={closeSheet} href="/workspaces">
              <div
                className={`flex items-center py-2 px-4 rounded-md w-[249px] ${activeItem === '/workspaces' ? 'bg-emerald-100 dark:bg-slate-800' : 'bg-rose-50 dark:bg-background'}`}
              >
                <GrBusinessService className="size-5 text-teal-600 mr-2 transition duration-200 hover:text-stone-500" />
                <span className="text-base font-semibold text-teal-600 dark:text-muted-foreground hover:text-rose-400">
                  My Workspaces
                </span>
              </div>
            </Link>
          </NavigationMenuItem>

          {/* Ueser Profile Settings */}
          <NavigationMenuItem className="ml-1 w-[250px] dark:border">
            <Link onClick={closeSheet} href="/profile">
              <div
                className={`flex items-center py-2 px-4 rounded-md w-[249px] ${activeItem === '/profile' ? 'bg-emerald-100 dark:bg-slate-800' : 'bg-rose-50 dark:bg-background'}`}
              >
                <Settings className="size-5 text-teal-600 mr-2 transition duration-200 hover:text-stone-500" />
                <span className="text-base font-semibold text-teal-600 dark:text-muted-foreground hover:text-rose-400">
                  Profile Settings
                </span>
              </div>
            </Link>
          </NavigationMenuItem>

          {/* Manage User Subscription, Billing and Plans & Pricing */}
          <NavigationMenuItem className="w-[250px] dark:border">
            <Link onClick={closeSheet} href="/billing">
              <div
                className={`flex items-center py-2 px-4 rounded-md w-[249px] ${activeItem === '/billing' ? 'bg-emerald-100 dark:bg-slate-800' : 'bg-rose-50 dark:bg-background'}`}
              >
                <CreditCard className="size-5 text-teal-600 mr-2 transition duration-200 hover:text-stone-500" />
                <span className="text-base font-semibold text-teal-600 dark:text-muted-foreground hover:text-rose-400">
                  Billing
                </span>
              </div>
            </Link>
          </NavigationMenuItem>

          {/* cName Domain/ White Label Setup */}
          <NavigationMenuItem className="w-[250px] dark:border">
            <Link onClick={closeSheet} href="/cname-domain">
              <div
                className={`flex items-center py-2 px-4 rounded-md w-[249px] ${activeItem === '/cname-domain' ? 'bg-emerald-100 dark:bg-slate-800' : 'bg-rose-50 dark:bg-background'}`}
              >
                <Tags className="size-5 text-teal-600 mr-2 transition duration-200 hover:text-stone-500" />
                <span className="text-base font-semibold text-teal-600 dark:text-muted-foreground hover:text-rose-400">
                  cName / White Label
                </span>
              </div>
            </Link>
          </NavigationMenuItem>

          {/* cName/ White Label Branding */}
          <NavigationMenuItem className="w-[250px] dark:border">
            <Link onClick={closeSheet} href="/cname-branding">
              <div
                className={`flex items-center py-2 px-4 rounded-md w-[249px] ${activeItem === '/cname-branding' ? 'bg-emerald-100 dark:bg-slate-800' : 'bg-rose-50 dark:bg-background'}`}
              >
                <SiBrandfolder className="size-5 text-teal-600 mr-2 transition duration-200 hover:text-stone-500" />
                <span className="text-base font-semibold text-teal-600 dark:text-muted-foreground hover:text-rose-400">
                  cName Branding
                </span>
              </div>
            </Link>
          </NavigationMenuItem>

          {/* AppSumo Coupon Redemption */}
          {/* <NavigationMenuItem className='w-[250px]'>
            <Link onClick={closeSheet} href='/redeem-appsumo-coupon'>
              <div className={`flex items-center py-2 px-4 rounded-md w-[249px] ${activeItem === '/redeem-appsumo-coupon' ? 'bg-emerald-100 dark:bg-slate-800' : 'bg-rose-50 dark:bg-background'}`}>
                <Ticket className='size-5 text-teal-600 mr-2 transition duration-200 hover:text-stone-500' />
                <span className='text-base font-semibold text-teal-600 dark:text-muted-foreground hover:text-rose-400'>
                  Got AppSumo Coupon?
                </span>
              </div>
            </Link>
          </NavigationMenuItem> */}

          {/* Onsite Coupon Redemption */}
          <NavigationMenuItem className="w-[250px] dark:border">
            <Link onClick={closeSheet} href="/redeem-onsite-coupon">
              <div
                className={`flex items-center py-2 px-4 rounded-md w-[249px] ${activeItem === '/redeem-onsite-coupon' ? 'bg-emerald-100 dark:bg-slate-800' : 'bg-rose-50 dark:bg-background'}`}
              >
                <Ticket className="size-5 text-teal-600 mr-2 transition duration-200 hover:text-stone-500" />
                <span className="text-base font-semibold text-teal-600 dark:text-muted-foreground hover:text-rose-400">
                  Have a Coupon?
                </span>
              </div>
            </Link>
          </NavigationMenuItem>

          {/* Documentation */}
          <NavigationMenuItem className="w-[250px] dark:border">
            <Link
              onClick={closeSheet}
              href="https://classifiedbilling.com/docs"
              target="blank"
            >
              <div
                className={`flex items-center py-2 px-4 rounded-md w-[249px] ${activeItem === '/redeem-onsite-coupon' ? 'bg-emerald-100 dark:bg-slate-800' : 'bg-rose-50 dark:bg-background'}`}
              >
                <BookCheck className="size-5 text-teal-600 mr-2 transition duration-200 hover:text-stone-500" />
                <span className="text-base font-semibold text-teal-600 dark:text-muted-foreground hover:text-rose-400">
                  Documentation
                </span>
              </div>
            </Link>
          </NavigationMenuItem>

          {/* Product Roadmap */}
          <NavigationMenuItem className="w-[250px] dark:border">
            <Link
              onClick={closeSheet}
              href="https://roadmap.classifiedbilling.com"
              target="blank"
            >
              <div
                className={`flex items-center py-2 px-4 rounded-md w-[249px] ${activeItem === '/redeem-onsite-coupon' ? 'bg-emerald-100 dark:bg-slate-800' : 'bg-rose-50 dark:bg-background'}`}
              >
                <SquareKanban className="size-5 text-teal-600 mr-2 transition duration-200 hover:text-stone-500" />
                <span className="text-base font-semibold text-teal-600 dark:text-muted-foreground hover:text-rose-400">
                  Product Roadmap
                </span>
              </div>
            </Link>
          </NavigationMenuItem>

          {/* Contact Support */}
          <NavigationMenuItem className="w-[250px] dark:border">
            <Link onClick={closeSheet} href="/support">
              <div
                className={`flex items-center py-2 px-4 rounded-md w-[249px] ${activeItem === '/support' ? 'bg-emerald-100 dark:bg-slate-800' : 'bg-rose-50 dark:bg-background'}`}
              >
                <HelpCircle className="size-5 text-teal-600 mr-2 transition duration-200 hover:text-stone-500" />
                <span className="text-base font-semibold text-teal-600 dark:text-muted-foreground hover:text-rose-400">
                  Contact Support
                </span>
              </div>
            </Link>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    </div>
  );
};

export default UserLeftNavigationMenu;
